<template>
    <div>
        <ul class="nav nav-tabs" id="myTab" role="tablist">
            <div class="'col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6'">
                <li class="nav-item" role="presentation">
                    <a class="nav-link" :class="{ active: activeTab == 0 }" @click="activeTab = 0" id="allotment-tab"
                       data-toggle="tab" href="#allotment" role="tab" aria-controls="allotment"
                       aria-disabled="true">{{ t('PROPOSAL.TEMPLATE') }}</a>
                </li>
            </div>
            <div class="'col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6'">
                <li class="nav-item" role="presentation">
                    <a class="nav-link" :class="{ active: activeTab == 1 }"
                       @click.stop.prevent="activeTab = 1"
                       id="localization-tab" data-toggle="tab" href="#localization" role="tab"
                       aria-controls="localization"
                       aria-disabled="true">{{ t('PROPOSAL.ALLOTMENTS') }}</a>
                </li>
            </div>
        </ul>
        <div>
            <form>
                <div class="tab-content modal-row2 modal-height-max" id="myTabContent">
                    <div class="tab-pane fade" :class="{ active: activeTab == 0, show: activeTab == 0 }" id="businnes"
                         role="tabpanel" aria-labelledby="businnes-tab">
                        <div class="modal-body">
                            <div class="row gutters">
                                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                    <div class="form-group">
                                        <label for="taxpayer" class="required">{{
                                                t('PROPOSAL.TEMPLATE_NAME')
                                            }}: </label>
                                        <input type="text" id="taxpayer" name="taxpayer" v-model="proposal.name"
                                               class="form-control"
                                               placeholder="Nome" disabled>
                                    </div>
                                </div>
                            </div>
                            <div class="row gutters">
                                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                    <div class="form-group">
                                        <label for="taxpayer" class="required">{{ t('PROPOSAL.TEMPLATE') }}: </label>
                                        <editor :init="configTinyMce" v-model="proposal.text"
                                                tinymce-script-src="https://tiny.r2app.com.br/js/tinymce/tinymce.min.js"
                                                disabled>
                                        </editor>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="tab-pane fade" :class="{ active: activeTab == 1, show: activeTab == 1 }"
                         id="businnesAlltoments"
                         role="tabpanel" aria-labelledby="businnes-tab">
                        <div class="modal-body">
                            <div class="row gutters" v-if="proposal?.allotments?.length">
                                <label for="taxpayer" class="ml-3">
                                    {{ t('NAV.ALLOTMENT') }}
                                </label>
                                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"
                                     v-for="(iten, key) in proposal.allotments" :key="key"
                                     :class="key != 0 ? 'border-top2': ''">
                                    <div class="list-primary hide-text p-2">
                                        <div class="w-auto">
                                            {{ iten.name }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row gutters" v-else>
                                <div class="card col-12">
                                    <div class="card-header"></div>
                                    <div class="card-body pt-0">
                                        <div class="text-center">
                                            <h5>{{ t('RESERVE.MSG.NONE_RECORD_FIND') }}</h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <div>
                        <button data-cy="client_add_close" class="btn btn-secondary mr-2" v-if="activeTab === 1"
                                @click="activeTab--"
                                type="button">
                            {{ t('ACTIONS.BACK') }}
                        </button>
                        <button data-cy="client_add_close" class="btn btn-secondary mr-2" data-dismiss="modal"
                                type="button">
                            {{ t('ACTIONS.CLOSE') }}
                        </button>
                        <button data-cy="client_add_close" class="btn btn-secondary mr-2" v-if="activeTab === 0"
                                @click="activeTab++"
                                type="button">
                            {{ t('ACTIONS.NEXT') }}
                        </button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
import {useToast} from "vue-toastification";
import {useI18n} from "vue-i18n";
import Editor from '@tinymce/tinymce-vue';
import Tags from '../../services/Tags';

export default {
    name: "showTemplateProposalComponent",
    setup() {
        const toast = useToast();
        const {t} = useI18n();
        return {t, toast}
    },
    props: {
        itenIndex: {
            type: Object
        }
    },
    data() {
        return {
            proposal: {},
            activeTab: 0,
            configTinyMce: {
                valid_elements: '*[*]', // Permite todos os elementos e atributos
                valid_classes: {
                    '*': 'tag-highlight tag-highlight-blue tag-highlight-green', // Permite a classe 'tag-highlight' em qualquer elemento
                },
                selector: 'textarea#open-source-plugins',
                license_key: 'gpl',
                plugins: 'print preview paste importcss searchreplace autolink autosave save directionality code visualblocks visualchars fullscreen image link media template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount imagetools textpattern noneditable help charmap quickbars emoticons',
                imagetools_cors_hosts: ['picsum.photos'],
                menubar: 'file edit view insert format tools table help',
                toolbar: 'undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | forecolor backcolor removeformat | pagebreak | charmap emoticons | fullscreen  preview save print | insertfile image media template link anchor codesample | ltr rtl',
                toolbar_sticky: true,
                autosave_ask_before_unload: true,
                autosave_interval: '30s',
                autosave_prefix: '{path}{query}-{id}-',
                autosave_restore_when_empty: false,
                autosave_retention: '2m',
                image_advtab: true,
                importcss_append: true,
                template_cdate_format: '[Date Created (CDATE): %m/%d/%Y : %H:%M:%S]',
                template_mdate_format: '[Date Modified (MDATE): %m/%d/%Y : %H:%M:%S]',
                height: 600,
                image_caption: true,
                quickbars_selection_toolbar: 'bold italic | quicklink h2 h3 blockquote quickimage quicktable',
                noneditable_noneditable_class: 'mceNonEditable',
                toolbar_mode: 'sliding',
                contextmenu: 'link image imagetools table',
                skin: 'oxide',
                content_css: 'default',
                language: 'pt_BR',
                content_style: `
                body { font-family:Helvetica,Arial,sans-serif; font-size:14px }
                .tag-highlight { background-color: #ffeb3b; padding: 2px 4px; border-radius: 3px; font-weight: bold; color: #000; }
                .tag-highlight-blue { background-color: #90CAF9; padding: 2px 4px; border-radius: 3px; font-weight: bold; color: #000; }
                .tag-highlight-green { background-color: #4CAF50; padding: 2px 4px; border-radius: 3px; font-weight: bold; color: #000; }
                `,
            },
            configEditor: {
                height: '300'
            },
            allTags: {},
        }
    },
    mounted() {
        this.proposal = this.itenIndex;
        this.listTags();
    },
    watch: {
        itenIndex() {
            this.proposal = this.itenIndex;
            this.activeTab = 0;
            this.listTags();
        }
    },
    components: {
        Editor
    },
    methods:{
        listTags() {
            this.$store.commit('changeLoading', true);
            Tags.list().then(resp => {
                resp.data.forEach(item => {
                    this.allTags[item.traduction_tag] = item.name_tag
                });
                this.proposal.text = this.highlightTagsInContent(this.proposal.text);
                this.$store.commit('changeLoading', false);
            }).catch(() => {
                this.$store.commit('changeLoading', false);
            });
        },
        decodeHTMLEntities(text) {
            const textArea = document.createElement('textarea');
            textArea.innerHTML = text;
            return textArea.value;
        },
        highlightTagsInContent(content) {
            return content.replace(/\{([^}]+)\}/g, (match) => {
                let matchD = this.decodeHTMLEntities(match);
                if (this.allTags[matchD]) {
                    if (this.allTags[matchD].includes('{if_exists_start.') || this.allTags[matchD].includes('{if_exists_end.')) {
                        return `<span class="tag-highlight-blue">${match}</span>`;
                    } else if (this.allTags[matchD].includes('{start_loop.') || this.allTags[matchD].includes('{end_loop.')) {
                        return `<span class="tag-highlight-green">${match}</span>`;
                    } else {
                        return `<span class="tag-highlight">${match}</span>`;
                    }
                }
                return match;
            });
        },
    }
}
</script>

<style scoped>

</style>