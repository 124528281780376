<template>
    <div>
        <load-screen-component/>
        <page-header :btnNew="'ACTIONS.NEW'" @newType="setRegisterModal" pageTitle="PROPOSAL.INDEX_TEMPLATE"
                     :haveSearch="true"
                     @searching="search($event)"/>
        <div v-if="items?.data" class="card">
            <div v-for="(iten, key) in items.data" :key="iten.id" :class="key !=0 ? 'border-top2': ''">
                <div class="row space-between mt-2" style="margin-left: -22px;">
                    <div class="col-xl-11 col-lg-11 col-md-11 col-sm-11 col-11" @click="open(iten)">
                        <div class="list-primary hide-text"> {{ itemsName[key].name }}</div>
                    </div>
                    <div class="col-xl-1 col-lg-1 col-md-1 col-sm-1 col-1 ">
                        <div class=" justify-content-end  d-flex mr-3">
                            <div class="text-right">
                                <a type="button" data-toggle="dropdown" aria-haspopup="true"
                                   aria-expanded="false"
                                   style="font-size: 20px" id="listDropdown">
                                    <i class="icon-more_vert" title="Opções"></i>
                                </a>
                                <div class="dropdown-menu dropdown-menu-right dropdown-black">
                                    <div class="dropdown-item pointer text-white"
                                         data-toggle="modal" data-target="#customModalTwo"
                                         @click="setViewModal(iten)">
                                        <i class="icon-eye1 mr-2 font-15"/>{{ t('ACTIONS.VIEW') }}
                                    </div>
                                    <div class="dropdown-item pointer text-white"
                                         data-toggle="modal" data-target="#customModalTwo"
                                         @click="setEditModal(iten)">
                                        <i class="icon-edit mr-2 font-15"/>{{ t('ACTIONS.EDIT') }}
                                    </div>
                                    <div class="dropdown-item pointer text-white"
                                         data-toggle="modal" data-target="#customModalTwo"
                                         @click="setCopyTemplate(iten)">
                                        <i class="icon-copy mr-2 font-15"/>{{ t('PROPOSAL.DUPLICATED') }}
                                    </div>
                                    <div class="dropdown-item pointer text-white"
                                         @click="deleteTemplate(iten)">
                                        <i class="icon-trash mr-2 font-15"/>{{ t('ACTIONS.DELETE') }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="card" v-else>
            <div class="card-header"></div>
            <div class="card-body pt-0">
                <div class="text-center">
                    <h5>{{ t('GENERAL.MSG.NONE_TEMPLATE') }}</h5>
                </div>
            </div>
        </div>
        <div class="row gutters w-100">
            <div class="col-3" v-if="!widthMd(1000)"></div>
            <div :class="!widthMd(1000) ? 'col-6' : 'col-12'">
                <pagination-component v-if="items && items.data && items.data[0]" :items="items" to="/templates/proposta"
                                      @changePage="index(filter, $event), this.page=$event"/>
            </div>
            <div
                    class="col-3 w-100" v-if="!widthMd(1000)">
                <div
                        class="row gutters d-flex justify-content-end w-100"
                        v-if="items && items.data && items.data[0]">
                    <div class="col-8">
                        <label>Pessoas por página: </label>
                        <Select2
                                :settings="{width: '100%'}"
                                placeholder="Selecione"
                                @select="changePaginate"
                                :options="paginate"
                                id="paginate"
                                name="paginate"
                                v-model="perPage"
                        ></Select2>
                    </div>
                </div>
            </div>
        </div>
        <modal :modal-extra-large="true" :titleModal="modalTitle">
            <template v-slot:title>{{ t(modalTitle) }}</template>
            <create-template-proposal-component v-if="modalChosen === 1" :iten-index="iten" @created="this.index"/>
            <show-template-proposal-component v-if="modalChosen === 2" :iten-index="iten"/>
            <edit-template-proposal-component v-if="modalChosen === 3" :iten-index="iten" @updated="this.index"/>
        </modal>
    </div>
</template>

<script>

import {mapState} from 'vuex';
import {useI18n} from 'vue-i18n';
import modal from '../../components/modal';
import {useToast} from 'vue-toastification';
import Proposal from '../../services/Proposal';
import pageHeader from '../../components/layouts/pageHeader';
import showTemplateProposalComponent from './showTemplateProposalComponent';
import editTemplateProposalComponent from './editTemplateProposalComponent';
import PaginationComponent from '../../components/layouts/PaginationComponent';
import loadScreenComponent from '../../components/layouts/loadScreenComponent';
import createTemplateProposalComponent from './createTemplateProposalComponent';
import widthMd from '../../mixins/widthMd';
import Select2 from 'vue3-select2-component';

export default {
    name: 'indexComponent',
    components: {
        modal,
        pageHeader,
        loadScreenComponent,
        PaginationComponent,
        showTemplateProposalComponent,
        editTemplateProposalComponent,
        createTemplateProposalComponent,
        Select2,
    },
    computed: {
        ...mapState({
            paginate: 'paginate',
        }),
    },
    setup() {
        const toast = useToast();
        const {t} = useI18n();
        return {t, toast};
    },
    mixins:[widthMd],
    data() {
        return {
            perPage: null,
            items: {},
            itemsName: null,
            iten: {},
            modalChosen: null,
            modalTitle: null,
            configEditor: {
                height: '400',
                width: 'auto',
            },
        };
    },
    mounted() {
        this.index();
    },
    methods: {
        setCopyTemplate(iten) {
            this.$store.commit('changeLoading', true);
            this.iten = iten;
            this.modalChosen = 1;
            this.modalTitle = 'PROPOSAL.ADD_TEMPLATE';
        },
        search(filter) {
            this.filter = {filtro: filter};
            this.index(this.filter, 1);
        },
        changePaginate() {
            this.index(this.filter, 1);
        },
        deleteTemplate(iten) {
            this.$swal.fire({
                title: this.t('GENERAL.MSG.REALLY_WANT_DELETE'),
                showCancelButton: true,
                showCloseButton: true,
                confirmButtonText: `Excluir`,
                cancelButtonText: 'Cancelar',
            }).then(result => {
                if (result.isConfirmed) {
                    this.$store.commit('changeLoading', true);
                    Proposal.deleteTemplate(iten.id).then(() => {
                        this.item = {};
                        this.itemsName = null;
                        this.iten = {};
                        this.index();
                    }).catch(err => this.errorMsg(err))
                        .finally(() => this.$store.commit('changeLoading', false));
                }
            });
        },
        async setViewModal(iten) {
            this.$store.commit('changeLoading', true);
			const response = await Proposal.showTemplate(iten.id);
			this.iten = response.data;
			this.modalChosen = 2;
			this.modalTitle = 'PROPOSAL.VIEW_TEMPLATE';
        },
        async setEditModal(iten) {
            this.$store.commit('changeLoading', true);
			const response = await Proposal.showTemplate(iten.id);
			this.iten = response.data;
			this.modalChosen = 3;
			this.modalTitle = 'PROPOSAL.EDIT_TEMPLATE';
        },
        setRegisterModal() {
            this.$store.commit('changeLoading', true);
            this.iten = {};
            this.modalChosen = 1;
            this.modalTitle = 'PROPOSAL.ADD_TEMPLATE';
        },
        open(iten) {
            iten.open = !iten.open ? true : false;
        },
        updateTemplate(item) {
            this.$store.commit('changeLoading', true);
            Proposal.updateTemplate(item, item.id).then(() => {
                this.toast.success(this.t('PROPOSAL.UPDATED_TEMPLATE'));
                this.index();
            }).catch(err => this.errorMsg(err))
                .finally(() => this.$store.commit('changeLoading', false));
        },
        index(filter = null, page = 1) {
            this.$store.commit('changeLoading', true);
            this.iten = {};
            Proposal.indexTemplate(filter, page, this.perPage).then(resp => {
                if (resp?.data.data) {
                    this.items = resp.data;
                    this.itemsName = this.items.data.map(item => {
                        return {name: item.name};
                    });
                }else {
                    this.items ={};
                }
            }).catch(err => {
                this.items ={};
                this.errorMsg(err)
            }).finally(() => this.$store.commit('changeLoading', false));
        },
        errorMsg(error) {
            const erro = error.response.data.validations.errors;
            if (erro.sql || !erro || erro.length === 0) {
                this.toast.error(error.response.data.validations.message, {
                    timeout: false,
                });
            } else {
                for (let column in erro) {
                    for (let msg in erro[column]) {
                        this.toast.error(erro[column][msg], {timeout: false});
                    }
                }
            }
        },
    },
};
</script>

<style scoped>
.button-center {
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: left;
    color: #333333;
    border-radius: 50px;
    font-size: 160%;
}

.table-settings {
    padding-top: 0.6rem !important;
    padding-right: 1rem !important;
    padding-bottom: 0.1rem !important;
    background-color: white !important;
}

.fade-enter-active, .fade-leave-active {
    -webkit-transition: .1s ease-out;
    -moz-transition: .1s ease-out;
    transition: .1s ease-out;
}

.fade-enter, .fade-leave-to {
    -webkit-transition: .1s ease-out;
    -moz-transition: .1s ease-out;
    transition: .1s ease-out;
}

.space-between {
    padding-bottom: 8px;
    align-items: center;
    justify-content: space-between;
}

.font-seven {
    font-size: 0.7rem !important;
}

.detail {
    display: flex;
    padding-left: 2%;
    padding-right: 2%;
    align-items: center;
    justify-content: space-between;
}

.dropdown-menu-custom {
    right: 0px !important;
    left: 13px !important;
    width: 7rem !important;
    min-width: 7rem !important;
}

.ml-negative-1 {
    margin-left: -3.5%;
    padding-left: 0px
}
</style>