<template>
    <form @submit.prevent="updateTemplate">
        <div class="modal-row2 modal-height-max" id="myTabContent">
            <div class="tab-pane" id="template">
                <div class="modal-body">
                    <div class="row mt-negative-1">
                        <div class="col-xl-3 col-lg-4 col-md-4 col-sm-4 col-4 overflow labels-container"
                             style="overflow-x: hidden; overflow-y: auto">
                            <div class="tags-block">
                                <h5>Tags</h5>
                                <div class="lablesContainerScroll">
                                    <div class="tags" v-for="(category, key) in tags.categories_raiz"
                                         :key="key">
                                        <a href="#" @click="alterOpenRaiz(key)">
                                            <i class="icon-label" :class="colorTag(key)"></i> {{ category }}<p class="ml-1 align-self-end" style="color: red" v-if="category === 'Compradores'">Loop</p>
                                            <i class="icon-expand_more" v-if="!openRaiz[key]?.open"></i>
                                            <i class="icon-expand_less font-weight-bold"
                                               v-if="openRaiz[key]?.open"></i>
                                        </a>
                                        <div class="ml-2" v-show="openRaiz[key]?.open">
                                            <div class="tags " v-for="(categoryc, keyc) in tags.items_raiz[key]"
                                                 :key="keyc"
                                                 :class="categoryc.open? 'border-bottom': ''">
                                                <a href="#" @click="categoryc.open = !categoryc.open">
                                                    <i class="icon-label"></i> {{ keyc }} <p class="ml-1 align-self-end" style="color: red" v-if="keyc === 'Signatários'">Loop</p>
                                                    <i class="icon-expand_more" v-if="!categoryc.open"></i>
                                                    <i class="icon-expand_less font-weight-bold"
                                                       v-if="categoryc.open"></i>
                                                </a>
                                                <div v-show="categoryc.open">
                                                    <div class="d-flex flex-row container align-items-start flex-wrap">
                                                        <div v-for=" (category) in categoryc"
                                                             :key="category.id">
                                                            <div class="badge badge-pill badge-light mb-1 mt-1 mr-1"
                                                                 :class="chooseColorNameTag(category.name_tag)"
                                                                 style="font-size: 0.73rem; white-space: normal; cursor: pointer"
                                                                 @click="copyText(`${key}${category.name_tag}`, category.traduction_tag)"
                                                                 :id="`${key}${category.name_tag}`">
                                                                {{ category.text }}
                                                                <i title="copiar" class="icon-copy"></i>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="tags" v-for="(category, key) in tags.categories" :key="key"
                                         :class="tags.items[key].open? 'border-bottom': ''">
                                        <a href="#" @click="tags.items[key].open = !tags.items[key].open">
                                            <i class="icon-label" :class="colorTag(key)"></i> {{ category }}
                                            <i class="icon-expand_more" v-if="!tags.items[key].open"></i>
                                            <i class="icon-expand_less font-weight-bold"
                                               v-if="tags.items[key].open"></i>
                                        </a>
                                        <div v-show="tags.items[key].open">
                                            <div class="d-flex flex-row container align-items-start flex-wrap">
                                                <div v-for=" (category) in tags.items[key]" :key="category.id">
                                                    <div class="badge badge-pill badge-light mb-1 mt-1 mr-1"
                                                         style="font-size: 0.73rem; white-space: normal; cursor: pointer"
                                                         @click="copyText(`${key}${category.name_tag}`, category.traduction_tag)"
                                                         :id="`${key}${category.name_tag}`">{{ category.text }}
                                                        <i title="copiar" class="icon-copy"></i>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-9 col-lg-8 col-md-8 col-sm-8 col-8 mt-3">
                            <div class="row gutters">
                                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                    <div class="form-group">
                                        <label for="name" class="required">{{
                                                t('PROPOSAL.TEMPLATE_NAME')
                                            }}: </label>
                                        <input type="text" id="name" name="name" v-model="proposal.name"
                                               class="form-control"
                                               placeholder="Nome">
                                        <p class="validation" v-if="requiredName">
                                            {{ t('GENERAL.MSG.REQUIRED_FIELD') }}</p>
                                    </div>
                                </div>
                            </div>
                            <div class="row gutters">
                                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                    <div class="form-group">
                                        <label class="required">{{ t('PROPOSAL.TEMPLATE') }}: </label>
                                        <editor :init="configTinyMce"
                                                v-model="proposal.text"
                                                tinymce-script-src="https://tiny.r2app.com.br/js/tinymce/tinymce.min.js"></editor>
                                        <p class="validation" v-if="requiredText">
                                            {{ t('GENERAL.MSG.REQUIRED_FIELD') }}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-footer" style="justify-content: space-between">
            <div>
                <h5 class="ml-3" style="color: red; font-size: .7rem;">{{ t('GENERAL.REQUIRED_FIELDS') }} *</h5>
            </div>
            <div class="tags-block mb-2 ml-2 row left-content justify-content-between paddin">
                <div class="d-flex flex-row align-items-center">
                    <div class="tags" v-for="sub in subtitles" :key="sub.id">
                        <div class="mr-2">
                            {{ `Tipo ${sub.position}: ${sub.label}` }}
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <button data-cy="client_add_close" class="btn btn-secondary mr-2" data-dismiss="modal"
                        type="button">
                    {{ t('ACTIONS.CLOSE') }}
                </button>
                <button data-cy="client_add_save" class="btn btn-primary" type="submit">
                    {{ t('ACTIONS.SAVE') }}
                </button>
            </div>
        </div>
    </form>
</template>

<script>

import {useI18n} from 'vue-i18n';
import Tags from '../../services/Tags';
import Editor from '@tinymce/tinymce-vue';
import {useToast} from 'vue-toastification';
import Proposal from '../../services/Proposal';
import SimulatorConfig from '../../services/SimulatorConfig';

export default {
    name: 'createTemplateProposalComponent',
    setup() {
        const toast = useToast();
        const {t} = useI18n();
        return {t, toast};
    },
    components: {
        Editor,
    },
    props: {
        itenIndex: {
            type: Object,
            required: true,
        },
    },
    watch: {
        itenIndex: function () {
            this.proposal = this.itenIndex;
            this.listTags();
            this.listLegends();
        },
    },
    data() {
        return {
            configTinyMce: {
                setup: (editor) => {
                    editor.on('paste', (e) => {
                        e.preventDefault();
                        const pastedText = (e.clipboardData || window.clipboardData).getData('text');
                        if (this.allTags[pastedText]) {
                            let highlightedTag;
                            if (this.allTags[pastedText].includes('{if_exists_start.') || this.allTags[pastedText].includes('{if_exists_end.')) {
                                highlightedTag = `<span class="tag-highlight-blue">${pastedText}</span> `;
                            } else if (this.allTags[pastedText].includes('{start_loop.') || this.allTags[pastedText].includes('{end_loop.')) {
                                highlightedTag = `<span class="tag-highlight-green">${pastedText}</span> `;
                            } else {
                                highlightedTag = `<span class="tag-highlight">${pastedText}</span> `;
                            }
                            editor.insertContent(highlightedTag);
                            const bookmark = editor.selection.getBookmark(2);
                            editor.selection.moveToBookmark(bookmark);
                        } else {
                            editor.insertContent(pastedText);
                        }
                    });

                    editor.on('keydown', (e) => {
                        const node = editor.selection.getNode();
                        if (node.tagName === 'SPAN' && (node.classList.contains('tag-highlight') || node.classList.contains('tag-highlight-blue') || node.classList.contains('tag-highlight-green'))) {
                            if (e.key.length === 1 || e.key === ' ') {
                                e.preventDefault();
                                const textNode = document.createTextNode(e.key === ' ' ? '\u00A0' : e.key);
                                if (node.nextSibling) {
                                    node.parentNode.insertBefore(textNode, node.nextSibling);
                                } else {
                                    node.parentNode.appendChild(textNode);
                                }
                                editor.selection.setCursorLocation(textNode, textNode.length);
                                editor.focus();
                            }

                            if (e.key === 'Enter') {
                                e.preventDefault();
                                const br = document.createElement('br');
                                if (node.nextSibling) {
                                    node.parentNode.insertBefore(br, node.nextSibling);
                                } else {
                                    node.parentNode.appendChild(br);
                                }
                                editor.selection.setCursorLocation(br, 0);
                                editor.focus();
                            }

                            if (e.key === 'Backspace') {
                                e.preventDefault();
                                const previousSibling = node.previousSibling;
                                if (previousSibling && previousSibling.nodeType === Node.TEXT_NODE && previousSibling.textContent.length > 0) {
                                    previousSibling.textContent = previousSibling.textContent.slice(0, -1);
                                }
                            }

                            if (e.key === 'Delete') {
                                e.preventDefault();
                                const nextSibling = node.nextSibling;
                                if (nextSibling && nextSibling.nodeType === Node.TEXT_NODE && nextSibling.textContent.length > 0) {
                                    nextSibling.textContent = nextSibling.textContent.slice(1);
                                }
                            }

                            if (['ArrowLeft', 'ArrowRight', 'ArrowUp', 'ArrowDown'].includes(e.key)) {
                                return;
                            }
                        }
                    });
                },
                valid_elements: '*[*]',
                valid_classes: {
                    '*': 'tag-highlight tag-highlight-blue tag-highlight-green',
                },
                selector: 'textarea#open-source-plugins',
                license_key: 'gpl',
                plugins: 'print preview paste importcss searchreplace autolink autosave save directionality code visualblocks visualchars fullscreen image link media template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount imagetools textpattern noneditable help charmap quickbars emoticons',
                imagetools_cors_hosts: ['picsum.photos'],
                menubar: 'file edit view insert format tools table help',
                toolbar: 'undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | forecolor backcolor removeformat | pagebreak | charmap emoticons | fullscreen  preview save print | insertfile image media template link anchor codesample | ltr rtl',
                toolbar_sticky: true,
                autosave_ask_before_unload: true,
                autosave_interval: '30s',
                autosave_prefix: '{path}{query}-{id}-',
                autosave_restore_when_empty: false,
                autosave_retention: '2m',
                image_advtab: true,
                importcss_append: true,
                template_cdate_format: '[Date Created (CDATE): %m/%d/%Y : %H:%M:%S]',
                template_mdate_format: '[Date Modified (MDATE): %m/%d/%Y : %H:%M:%S]',
                height: 600,
                image_caption: true,
                quickbars_selection_toolbar: 'bold italic | quicklink h2 h3 blockquote quickimage quicktable',
                noneditable_noneditable_class: 'mceNonEditable',
                toolbar_mode: 'sliding',
                contextmenu: 'link image imagetools table',
                skin: 'oxide',
                content_css: 'default',
                language: 'pt_BR',
                content_style: `
                body { font-family:Helvetica,Arial,sans-serif; font-size:14px }
                .tag-highlight { background-color: #ffeb3b; padding: 2px 4px; border-radius: 3px; font-weight: bold; color: #000; }
                .tag-highlight-blue { background-color: #90CAF9; padding: 2px 4px; border-radius: 3px; font-weight: bold; color: #000; }
                .tag-highlight-green { background-color: #4CAF50; padding: 2px 4px; border-radius: 3px; font-weight: bold; color: #000; }
                `,
            },
            proposal: {},
            requiredName: false,
            requiredText: false,
            tags: {items: [], categories: [], categories_raiz: [], items_raiz: []},
            openRaiz: [],
            allTags: {},
            allTagsColor: {},
            allTagsColorEncoded: {},
            subtitles: [],
        };
    },
    mounted() {
        this.proposal = this.itenIndex;
        this.listTags();
        this.listLegends();
    },
    methods: {
        highlightTagsInContent(content) {
            return content.replace(/\{([^}]+)\}/g, (match) => {
                let matchD = this.decodeHTMLEntities(match);
                if (this.allTags[matchD]) {
                    if (this.allTags[matchD].includes('{if_exists_start.') || this.allTags[matchD].includes('{if_exists_end.')) {
                        return `<span class="tag-highlight-blue">${match}</span>`;
                    } else if (this.allTags[matchD].includes('{start_loop.') || this.allTags[matchD].includes('{end_loop.')) {
                        return `<span class="tag-highlight-green">${match}</span>`;
                    } else {
                        return `<span class="tag-highlight">${match}</span>`;
                    }
                }
                return match;
            });
        },
        encodeHTML(str) {
            return str.replace(/ã/g, '&atilde;')
                    .replace(/á/g, '&aacute;')
                    .replace(/â/g, '&acirc;')
                    .replace(/é/g, '&eacute;')
                    .replace(/ê/g, '&ecirc;')
                    .replace(/í/g, '&iacute;')
                    .replace(/ó/g, '&oacute;')
                    .replace(/ô/g, '&ocirc;')
                    .replace(/õ/g, '&otilde;')
                    .replace(/ú/g, '&uacute;')
                    .replace(/ç/g, '&ccedil;');
        },
        decodeHTMLEntities(text) {
            const textArea = document.createElement('textarea');
            textArea.innerHTML = text;
            return textArea.value;
        },
        alterOpenRaiz(key) {
            if (this.openRaiz[key] === undefined) this.openRaiz[key] = {};
            this.openRaiz[key].open = !this.openRaiz[key]?.open
        },
        chooseColorNameTag(tag) {
            if (tag.includes('{if_exists_start.') || tag.includes('{if_exists_end.')) {
                return 'bg-info text-white';
            }
            if (tag.includes('{start_loop.') || tag.includes('{end_loop.')) {
                return 'bg-primary text-white';
            }
            return '';
        },
        copyText(id, textTag) {
            navigator.clipboard.writeText(textTag);
        },
        colorTag(key) {
            if (key > 6) key = (key % 6) - 1;
            if (key === 0) return 'text-primary';
            if (key === 1) return 'text-secondary';
            if (key === 2) return 'text-info';
            if (key === 3) return 'text-warning';
            if (key === 4) return 'text-success';
            if (key === 5) return 'text-danger';
            if (key === 6) return 'text-success';
            if (key) return 'text-primary';
        },
        listTags() {
            this.$store.commit('changeLoading', true);
            Tags.list().then(resp => {
                resp.data.forEach(item => {
                    this.allTags[item.traduction_tag] = item.name_tag
                    if (item.categoria_raiz && !this.tags.categories_raiz.includes(item.categoria_raiz)) {
                        this.tags.categories_raiz.push(item.categoria_raiz);
                    } else if (!item.categoria_raiz) {
                        if (!this.tags.categories.includes(item.category)) {
                            this.tags.categories.push(item.category);
                        }
                    }
                });
                for (const respKey in this.allTags) {
                    if (this.allTags[respKey].includes('{if_exists_start.') || this.allTags[respKey].includes('{if_exists_end.')) {
                        this.allTagsColor[respKey] = `<span class="tag-highlight-blue">${respKey}</span>`;
                        this.allTagsColorEncoded[respKey] = `<span class="tag-highlight-blue">${this.encodeHTML(respKey)}</span>`;
                    } else if (this.allTags[respKey].includes('{start_loop.') || this.allTags[respKey].includes('{end_loop.')) {
                        this.allTagsColor[respKey] = `<span class="tag-highlight-green">${respKey}</span>`;
                        this.allTagsColorEncoded[respKey] = `<span class="tag-highlight-green">${this.encodeHTML(respKey)}</span>`;
                    } else {
                        this.allTagsColor[respKey] = `<span class="tag-highlight">${respKey}</span>`;
                        this.allTagsColorEncoded[respKey] = `<span class="tag-highlight">${this.encodeHTML(respKey)}</span>`;
                    }
                }
                this.tags.categories.forEach((category, key) => {
                    this.tags.items[key] = []
                    resp.data.forEach(item => {
                        if (item.category === category) {
                            this.tags.items[key].push(item);
                        }
                    });
                })
                this.tags.categories_raiz.forEach((category, key) => {
                    this.tags.items_raiz[key] = [];
                    resp.data.forEach(item => {
                        if (item.categoria_raiz === category) {
                            this.tags.items_raiz[key].push(item);
                        }
                    });
                })
                if (this.tags.items_raiz?.length) {
                    this.tags.items_raiz.forEach((item, key) => {
                        let aux = {};
                        item?.forEach(i => {
                            if (!aux[i.category]) {
                                if (aux[i.category] === undefined) aux[i.category] = [];
                                aux[i.category].push(i);
                            } else {
                                aux[i.category].push(i);
                            }
                        })
                        this.tags.items_raiz[key] = aux;
                    })
                }
                this.proposal.text = this.highlightTagsInContent(this.proposal.text);
                this.$store.commit('changeLoading', false);
            }).catch(() => {
                this.$store.commit('changeLoading', false);
            });
        },
        listLegends() {
            const allotmentId = this.proposal?.allotments ? this.proposal?.allotments[0]?.id : null
            this.$store.commit('changeLoading', true);

            SimulatorConfig.listInstallmentPosition(allotmentId)
                    .then(({data}) => this.subtitles = data)
                    .catch(err => this.errorMsg(err))
                    .finally(() => this.$store.commit('changeLoading', false));
        },
        updateTemplate() {
            let data = JSON.parse(JSON.stringify(this.proposal));
            if (this.allTags){
                for (const key in this.allTagsColor) {
                    data.text = data.text.replaceAll(this.allTagsColor[key], key);
                }
                for (const key in this.allTagsColorEncoded) {
                    data.text = data.text.replaceAll(this.allTagsColorEncoded[key], key);
                }
            }
            if (this.findSpanTagHighlight(data.text)){
                return;
            }
            this.$store.commit('changeLoading', true);
            Proposal.updateTemplate(data, this.proposal.id).then(() => {
                this.toast.success(this.t('PROPOSAL.UPDATED_TEMPLATE'));
                this.$emit('updated');
                document.getElementById('closeX').click();
            }).catch(err => {
                this.$store.commit('changeLoading', false)
                this.errorMsg(err)
            });
        },
        findSpanTagHighlight(texto) {
            if (!texto.includes("tag-highlight")) {
                return null;
            }
            let positionTag = texto.indexOf("tag-highlight");
            let initialSpan = texto.lastIndexOf("<span", positionTag);
            let endSpan = texto.indexOf("</span>", positionTag) + 7;
            if (initialSpan !== -1 && endSpan !== -1) {
                let finded = texto.substring(initialSpan, endSpan);
                this.toast.error(`Documento contem tag mal formatada: ${finded}`);
                return true;
            }
            this.toast.error(`Documento contem tag mal formatada entre ${initialSpan} e ${endSpan}`);
            return true;
        },
        errorMsg(error) {
            const erro = error.response.data.validations.errors;

            if (erro.sql || !erro) {
                this.toast.error(error.response.data.validations.message, {
                    timeout: false,
                });
            } else {
                for (let column in erro) {
                    for (let msg in erro[column]) {
                        this.toast.error(erro[column][msg], {timeout: false});
                    }
                }
            }
        },
    },
};
</script>

<style scoped>
.mt-negative-1 {
    margin-top: -1.2%;
}

div::-webkit-scrollbar {
    width: 5px; /* width of the entire scrollbar */
}
</style>